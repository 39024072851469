import CalculatorClass from "../components/calculator_class/Calculator";
import imageProgramCalculator from "../images/business-color_calculator_icon-icons.com_53466.png";
import MyClock from "../components/clock/MyClock";
import imageProgramClock from "../images/clock-icon-169140282.png";
import LeafletScript from "../components/leaflet/LeafletScript";
import imageProgramLeaflet from "../images/location_map_pin_mark_icon_148685.png";
import imageProgramSettings from "../images/settings_78352.png";
import WeatherScript from "../components/leaflet/WeatherScript";
import Settings from "../components/settings/Settings";
import Radio from "../components/radio/Radio";
import imageProgramRadio from "../images/radio_23683.png";
import Arkanoid from "../components/arkanoid/Arkanoid";
import imageProgramArkanoid from '../images/arkanoid.ico';
import Inception from "../components/inception/Inception";
import imageProgramInception from '../images/1458264592_laptop_computer_pc_device_notebook_netbook_internet_icon-icons.com_55334.png';

// https://icon-icons.com/download/148685/PNG/512/

const programs = [
    {
        name: 'Calculator',
        component: CalculatorClass,
        imageUrl: imageProgramCalculator,
        isOpened: false,
    }
    ,{
        name: 'Clock',
        component: MyClock,
        imageUrl: imageProgramClock,
        isOpened: false,
        hiddenOnMobile: true,
    }
    ,{
        name: 'Map',
        component: LeafletScript,
        imageUrl: imageProgramLeaflet,
        isOpened: false,
        canBeMaximized: true,
    }
    ,{
        name: 'Settings',
        component: Settings,
        imageUrl: imageProgramSettings,
        isOpened: false,
        canBeMaximized: false,
        hiddenOnMobile: true,
    }
    ,{
        name: 'Radio',
        component: Radio,
        imageUrl: imageProgramRadio,
        isOpened: false,
        canBeMaximized: true,
    }
    ,{
        name: 'Arkanoid Intergalactic',
        component: Arkanoid,
        imageUrl: imageProgramArkanoid,
        isOpened: true,
        canBeMaximized: false,
        hiddenOnMobile: true,
    }
    ,{
        name: 'Mobile Inception',
        component: Inception,
        imageUrl: imageProgramInception,
        isOpened: false,
        canBeMaximized: false,
        hiddenOnMobile: true,
    }
    // ,{
    //     name: 'Weather Map',
    //     component: WeatherScript,
    //     imageUrl: imageProgramLeaflet,
    //     isOpened: true,
    // }
]

let programSettings = { // NOT USED YET
    // 'Calculator': true,
    // 'Clock': false,
    // 'Leaflet Map': false,
    // 'Weather Map': false,
    // 'Settings': true,
}

export { programs, programSettings };