import React, {useEffect} from "react";
import * as L from "leaflet"
import './Leaflet.css'

const MyMapComponentScript = () => {

    // var map;

    // useEffect( () => {
    //     map = L.map('map').setView([51.505, -0.09], 13);
    // })

    // setTimeout( () => {
    useEffect( () => {
        if (document.querySelector('#map') !== null) {
            // console.log(typeof L.map,'Loading map');
            try {
                let osm = L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 19,
                    attribution: '© OpenStreetMap'
                });

                let mapboxUrl = 'https://api.mapbox.com/styles/v1/mapbox/outdoors-v11.html?title=true&access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4M29iazA2Z2gycXA4N2pmbDZmangifQ.-g_vE53SD2WrJ6tFX7QHmA#5.06/52.16/11.96';

                let streets = L.tileLayer(mapboxUrl, {id: 'mapbox/streets-v11', tileSize: 512, zoomOffset: -1, attribution: {}});
                let baseMaps = {
                    "OpenStreetMap": osm,
                    "Mapbox Streets": streets
                };

                // let overlayMaps = {
                //     "Cities": cities
                // };
                // https://leafletjs.com/examples/layers-control/

                // console.log('map, Creating instance');
                window.map = L.map('map').setView([51.505, -0.09], 13);
                // http://maps.openweathermap.org/maps/2.0/weather/WND/{z}/{x}/{y}?date=1527811099&use_norm=true&arrow_step=16&appid={API key}
                // console.log('map, Adding layer');
                L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    maxZoom: 19,
                    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
                    layers: [osm, streets],
                }).addTo(window.map);
                let layerControl = L.control.layers(baseMaps).addTo(window.map);
                // function onMapClick(e) {
                //     alert("You clicked the map at " + e.latlng);
                // }
                window.map.on('click', (e) => {
                    console.log('You clicked at ', e.latlng, e);
                    var marker = new L.Marker([e.latlng.lat, e.latlng.lng]);
                    marker.addTo(window.map);
                    marker.on('click', (e) => {
                        marker.removeFrom(window.map);
                    })
                });
                setTimeout(function () {
                    // console.log('map, invalidateSize()');
                    if (typeof window.map!=='undefined') window.map.invalidateSize();
                }, 800);
            } catch (err) {
                // console.info('map, ', typeof window.map, err);
                if (typeof window.map!=='undefined' && typeof window.map.invalidateSize === 'function') {
                    // console.log('map, invalidateSize()');
                    window.map.invalidateSize();
                }
            }
        } else {
            console.info('Not found #map element');
        }

        return function cleanup() {
            // console.log('cleanup from map constainer');
            // if (map && map.remove) {
            //     console.log('cleanup from map constainer (in progress)');
            //     map.off();
            //     map.remove();
            // }
        }
    })
    // }, 1000)

    return (
        <div id='map' className={'map'}></div>
    )
}

export default MyMapComponentScript;