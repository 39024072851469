import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStore } from 'redux'
import allReducers from "./reducers";
import {Provider, useDispatch} from "react-redux";
import {Helmet} from "react-helmet";
import {increment, loadPrograms, openWindow} from "./actions";

// https://github.com/jeromegn/Backbone.localStorage
// import {LocalStorage} from 'backbone.localstorage';
// import {Collection, Model} from 'backbone';

import {programSettings} from "./config/programs";
// import {loadPrograms} from "./actions";
// import programs from "./config/programs";

const store = createStore(
    allReducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

// // https://www.youtube.com/watch?v=CVpUuw9XSjY
// https://github.com/zalmoxisus/redux-devtools-extension
// https://github.com/reduxjs/redux-devtools
// // ACTION
// const increment = () => {
//     return {
//         type: 'INCREMENT',
//     }
// }
// const decrement = () => {
//     return {
//         type: 'DECREMENT',
//     }
// }
//
// // REDUCER
// const counter = (state = 0, action) => {
//     switch (action.type){
//         case 'INCREMENT':
//             return state+1
//         case 'DECREMENT':
//             return state-1
//     }
// }
//
// let store = createStore(counter);
// store.subscribe( () => console.log(store.getState()));
//
// // DISPATCH
store.dispatch(increment())
store.dispatch(openWindow('Arkanoid'))


window.map = null;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Helmet>
          <link rel="stylesheet" href="https://unpkg.com/leaflet@1.9.2/dist/leaflet.css"
                integrity="sha256-sA+zWATbFveLLNqWO2gtiw3HL/lh1giY/Inf1BJ0z14=" crossOrigin=""/>
          <script src="https://unpkg.com/leaflet@1.9.2/dist/leaflet.js"
                  integrity="sha256-o9N1jGDZrf5tS+Ft4gbIK7mYMipq9lqpVJ91xHSyKhg=" crossOrigin=""></script>
      </Helmet>
      <Provider store={store}>
        <App />
      </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
