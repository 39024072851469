import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {increment} from "../actions";
import Switch from "./switch/Switch";

const ToggleDisplayMode = () => {

    const [ isDarkMode, setDarkMode ] = useState(false)

    const dispatch = useDispatch();

    const counter = useSelector( state => state.counter );

    // let isDarkMode = false;
    const toggleDarkMode = () => {
        console.log('Changing mode...');
        dispatch( increment() );
        // let nextIsDarkMode = !isDarkMode;
        document.querySelector('html').setAttribute('mode', (!isDarkMode ? 'dark' : '') );
        setDarkMode(!isDarkMode)
    }

    return (
        <>
                <Switch id='toggleDarkMode' isOn={isDarkMode} handleToggle={()=>toggleDarkMode()}/>
                <div>Dark mode</div>
        </>
    )
}

export default ToggleDisplayMode;