

const windowsManagerReducer = (state = [], action) => {
    switch (action.type) {
        // case 'LOAD_PROGRAMS':
        //     console.log('Loading ', state, action);
        //     programSettings = state
        //     return state;
        case 'CLOSE_WINDOW':
            console.log('Closing window', state, action);
            state = state.filter( name => name !== action.payload );
            return state;
        case 'OPEN_WINDOW':
            console.log('Opening window', state, action);
            if (!state.includes( action.payload )) {
                state.push(action.payload);
            }
            console.log('Opening window', state, action);
            return state;
        default:
            return state;
    }
}

export default windowsManagerReducer;