export const increment = () => {
    return {
        type: 'INCREMENT'
    }
}

export const closeWindow = (name) => {
    return {
        type: 'CLOSE_WINDOW',
        payload: name
    }
}

export const openWindow = (name) => {
    return {
        type: 'OPEN_WINDOW',
        payload: name
    }
}

export const loadPrograms = (programs) => {
    return {
        type: 'LOAD_PROGRAMS',
        payload: programs
    }
}

export const isOpenWindow = (name) => {
    return {
        type: 'IS_OPEN_WINDOW',
        payload: name
    }
}