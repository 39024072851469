import './App.css';
import React, {createContext, useEffect, useState} from "react";
import { BrowserRouter as Router, NavLink, Routes, Route } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Window from './components/window/Window'
import {programs, programSettings} from "./config/programs";
import {loadPrograms, openWindow} from "./actions";
import ToggleDisplayMode from "./components/ToggleDisplayMode";
import allReducers from "./reducers";



// const DocumentContext = createContext(document);

const Navbar = ({ username }) => {
    const counter = useSelector(state=>state.counter)
    return (
    <ul className="Menu">
        <li>
            <NavLink to='/Calculator'>Calculator</NavLink>
        </li>
        <li>
            <NavLink to='/Clock'>Clock</NavLink>
        </li>
        <li>
            <NavLink to='/HexaContainer'>HexaContainer</NavLink>
        </li>
        <li>
            <NavLink to='/LogoContainer'>LogoContainer</NavLink>
        </li>
        <li>
            <NavLink to={`/user/${username}`}>User</NavLink>
        </li>
        <div>Selector: {counter}</div>
    </ul>
    )
}



const Windows = ({openedPrograms = [], currentWindowName}) => {
    // const windowedElements = Object.keys(programs).map( name => Window(programs[name], {name: name, id: 'window_id_' + Object.keys(programs).indexOf(name).toString(), xPos:100, yPos:200}))
    const windowedElements = programs.filter( program => true ).map( program => {
        let index = programs.map(p => p.name).indexOf(program.name)
        return Window(program.component, {
                name: program.name,
                id: 'window_id_' + index.toString(),
                xPos: 100,
                yPos: 200,
                isOpened: openedPrograms.includes(program.name),
                canBeMaximized: program.canBeMaximized,
                isCurrentWindow: (currentWindowName === program.name ? true : false),
            })
        }
    )
    return ( <>{windowedElements}</>)
}



function App() {

    let dispatch = useDispatch();

    const openedPrograms = useSelector( state => state.windowsManager );
    const [ counter, setCounter ] = useState(0)

    console.log('openedPrograms: ', ...openedPrograms);

    // let programsWithoutComponents;
    // console.log('Loading programsWithoutComponents...', programsWithoutComponents)
    // console.log('Loading programs...', ...programs)
    // programsWithoutComponents = programs.map( p => { p.component = ''; return p; })
    // console.log('Loading programsWithoutComponents...', programsWithoutComponents)
    // console.log('Loading programs...', programs)
    // dispatch( loadPrograms( programs ) );
    // console.log('Loading programs...', programs)

    // useEffect( () => {
    //     console.log('Loading programs...')
    //     programsWithoutComponents = [...programs.map( p => { p.component = ''; return p; })]
    //     dispatch( loadPrograms( programsWithoutComponents ) );
    // }, [])

    const openProgram = (programName) => {
        setCurrentWindowName(programName);
        console.log('openProgram', programName, currentWindowName);
        dispatch( openWindow(programName) );
        setCounter(counter + 1 );
    }


    const Icon = (options) => {
        return (
            <div className={'programIcon ' + (options.hiddenOnMobile===true ? 'hiddenOnMobile' : '') + ' ' + (options.isCurrentWindowIcon ? 'isCurrentWindowIcon': '')} onClick={()=>openProgram(options.name)}>
                <div className={'programIconImage'}>
                    <img src={options.imageUrl} alt='icon'/>
                </div>
                <div className={'programIconName'}>
                    {options.name}
                </div>
            </div>
        )
    }

    const ProgramIcons = ({currentWindowName}) => {
        const iconSize = 40;
        const programIcons = programs.map( program => {
            let index = programs.map(p => p.name).indexOf(program.name);
            // return <Icon key={index} name={programs[name]} options={{name: name, id: 'icon_id_' + index.toString(), xPos:index * iconSize, yPos:40}} />
            return Icon(
                {
                    name: program.name,
                    id: 'icon_id_' + index.toString(),
                    xPos:index * iconSize,
                    yPos:40,
                    imageUrl: program.imageUrl,
                    hiddenOnMobile: program.hiddenOnMobile,
                    isCurrentWindowIcon: currentWindowName === program.name,
                }
            )
        })
        return (
            <>
                {programIcons}
            </>
        )
    }

    const [currentWindowName, setCurrentWindowName ] = useState(null);

    //                            <ToggleDisplayMode />

  return (
      <Router>
            <div className="App">
                <div className='desktopBackground'>
                    <main className="Main">
                        <div className={'ProgramIconContainer'}>
                            <ProgramIcons  currentWindowName={currentWindowName} />
                        </div>
                        <div className={'Windows'}>
                            <Windows openedPrograms={openedPrograms} currentWindowName={currentWindowName} />
                        </div>
                    </main>
                </div>

              {/*<main className="Main">*/}
              {/*    <Routes>*/}
              {/*        <Route path='/LogoContainer' element={<LogoContainer/>} />*/}
              {/*        <Route path='/HexaContainer' element={<HexaContainer/>} />*/}
              {/*        <Route path='/Calculator' element={<CalculatorClass/>} />*/}
              {/*        <Route path='/Clock' element={<Clock/>} />*/}
              {/*        <Route path='/' element={<CalculatorClass/>} />*/}
              {/*    </Routes>*/}
              {/*</main>*/}
            </div>
      </Router>
  );
}

export default App;
