import counterReducer from "./counter";
import loggedReducer from "./isLogged";
import { combineReducers } from "redux";
import windowsManagerReducer from "./windowsManager";

const allReducers = combineReducers({
  counter: counterReducer,
  isLogged: loggedReducer,
  windowsManager: windowsManagerReducer,
})

export default allReducers;
