import React, {useContext, useState, useRef, useCallback, useEffect} from "react";
import './Window.css'
import { useDispatch} from "react-redux";
import {closeWindow} from "../../actions";
import closeIcon from '../../images/close_111152.png';

const Window = (Element, options) => {

    // https://stackoverflow.com/questions/53099374/not-able-to-move-div-using-mousemove-in-react
    let { id, name } = options ;
    let isDragActive = false;

    let dx,dy; // offset between clicked point and position of window
    let maxXPos, maxYPos;

    let pointerToWindow;
    let pointerToBody;

    let listenerBodyOnMouseMove = null;
    let listenerBodyOnMouseOut;
    let listenerBodyOnMouseUp;
    let listenerBodyOnTouchMove;

    let desktopBox;
    let windowBox;

    const [isWindowMinimized, setWindowMinimized] = useState(false);
    const [isWindowMaximized, setWindowMaximized] = useState(false);
    const [isWindowOpened, setWindowOpened] = useState(false);

    const [isActive, setActive] = useState(false);

    const [ additionalWindowClassName, setAdditionalWindowClassName ] = useState('');

    const dispatch = useDispatch();

    const handleStartDragging = (e) => {
        setActive(true);
        isDragActive = true;
        console.log('handleStartDragging', e.type);

        pointerToBody = document.querySelector('main');
        pointerToWindow =  document.querySelector('#' + id);
        windowBox = pointerToWindow.getBoundingClientRect();
        desktopBox = pointerToBody.getBoundingClientRect();
        maxXPos = desktopBox.width - windowBox.width;
        maxYPos = desktopBox.height - windowBox.height;

        if (['touchmove','touchstart', 'touchend'].includes(e.type)) {
            dx = e.touches[0].pageX - windowBox.left;
            dy = e.touches[0].pageY - windowBox.top;
        } else {
            dx = e.clientX - windowBox.left;
            dy = e.clientY - windowBox.top;
        }

        if (typeof listenerBodyOnMouseOut !== 'function') {
            listenerBodyOnMouseOut = (e) => {
                console.log('listenerBodyOnMouseOut')
                stopDragging()
            }
        }

        if (typeof listenerBodyOnMouseUp !== 'function') {
            listenerBodyOnMouseUp = (e) => {
                stopDragging()
            }
        }

        if (typeof listenerBodyOnMouseMove !== 'function') {
            listenerBodyOnMouseMove = (e) => {
                console.log('listenerBodyOnMouseMove')
                setPositionWindow(e.clientX - dx, e.clientY - dy)
            }
            console.log('Creating body listener')
            pointerToBody.addEventListener('mousemove', listenerBodyOnMouseMove)
            pointerToBody.addEventListener('mouseleave', listenerBodyOnMouseOut)
            pointerToBody.addEventListener('mouseup', listenerBodyOnMouseUp)

        }

        if (typeof listenerBodyOnTouchMove !== 'function') {
            listenerBodyOnTouchMove = (e) => {
                let clientX = e.touches[0].pageX;
                let clientY = e.touches[0].pageY;
                console.log('listenerBodyOnTouchMove', clientX, clientY, dx, dy,  e)
                setPositionWindow(clientX - dx, clientY - dy)
            }
            pointerToBody.addEventListener('touchmove', listenerBodyOnTouchMove)
            pointerToBody.addEventListener('touchend', listenerBodyOnMouseUp)
            // pointerToBody.addEventListener('touchmove', listenerBodyOnMouseMove)

        }

    }

    const stopDragging = () => {
        setActive(false);
        isDragActive = false;
        pointerToBody.removeEventListener('mousemove', listenerBodyOnMouseMove);
        pointerToBody.removeEventListener('mouseleave', listenerBodyOnMouseOut);
        pointerToBody.removeEventListener('mouseleave', listenerBodyOnMouseUp);

        pointerToBody.removeEventListener('touchmove', listenerBodyOnTouchMove)
        pointerToBody.removeEventListener('touchend', listenerBodyOnMouseUp)
        listenerBodyOnMouseMove = null;
    }

    const setPositionWindow = (left, top) => {
        // Make sure don't exceed body window boundary
        console.log('setPositionWindow', left, top);
        left = Math.max(desktopBox.left, left)
        left = Math.min(desktopBox.left + desktopBox.width - windowBox.width, left)
        top = Math.max(desktopBox.top, top)
        top = Math.min(desktopBox.top + desktopBox.height - windowBox.height, top)
        pointerToWindow.style.left = left + 'px';
        pointerToWindow.style.top =  top + 'px';
    }

    const handleCloseWindow = e => {
        console.log('Closing window')
        // pointerToWindow.style.display = 'none';
        // alert('Sorry, doesnt work yet')
        dispatch( closeWindow(name) );
        setWindowOpened( false );
    }

    const MinimizedElement = ({name}) => {
        return (
            <>
                <div>{name}</div>
                {/*<div>*/}
                {/*    <button className={'windowButton windowButtonLeftSide'} onClick={()=>{*/}
                {/*        setWindowMinimized(false);*/}
                {/*        setAdditionalWindowClassName('leftHalfOfScreenWindow');*/}
                {/*    }}></button>*/}
                    <button className={'windowButton windowButtonMinimize'} onClick={()=>{
                        setWindowMinimized(false);
                        setAdditionalWindowClassName('')
                    }}></button>
                    {/*<button className={'windowButton windowButtonRightSide'} onClick={()=>{*/}
                    {/*    setWindowMinimized(false);*/}
                    {/*    setAdditionalWindowClassName('rightHalfOfScreenWindow')*/}
                    {/*}}></button>*/}
                    {/*<button className={'windowButton windowButtonMaximize'} onClick={()=>{*/}
                    {/*    setWindowMinimized(false);*/}
                    {/*    setAdditionalWindowClassName('windowFullscreen');*/}
                    {/*    toggleMaximizeWindow();*/}
                    {/*}}></button>*/}
                {/*</div>*/}
            </>
        )
    }

    useEffect( () => {
        setWindowOpened( options.isOpened );
    })

    const toggleMaximizeWindow = () => {
        // setWindowMinimized( )
        setWindowMaximized( !isWindowMaximized );
    }

    return (
        <>
            <div id={id} className={
                'window ' + (isActive ? 'activeWindow' : '') + ' '
                + (options.isCurrentWindow ? 'isCurrentWindow' : '') +' '
                + (isWindowOpened && isWindowMaximized ? 'windowFullscreen' :'' ) +' '
                + (isWindowOpened && !isWindowMinimized ? '' : 'hidden') + ' '
                + (additionalWindowClassName!=='' ? additionalWindowClassName : '') + ' '
            }>
                <div className={'windowHeader ' + (isWindowOpened && !isWindowMaximized ? '' : 'hidden')}>
                    <button className={'windowButton windowButtonClose'} onClick={handleCloseWindow}></button>
                    <div
                         className={'moveHeaderHandler'}
                         onMouseDown={handleStartDragging}
                         onTouchStart={handleStartDragging}>
                        {name}
                    </div>
                    {/*<button className={'windowButton windowButtonLeftSide'} onClick={()=>setAdditionalWindowClassName('leftHalfOfScreenWindow')}></button>*/}
                    <button className={'windowButton windowButtonMinimize'} onClick={()=>{
                        setAdditionalWindowClassName('');
                        setWindowMinimized(true);
                    }}>.</button>
                    {/*<button className={'windowButton windowButtonRightSide'} onClick={()=>setAdditionalWindowClassName('rightHalfOfScreenWindow')}></button>*/}

                    {/*<button className={'windowButton windowButtonMinimize'} onClick={()=>setWindowMinimized(true)}></button>*/}
                    {/*<button className={'windowButton windowButtonMaximize'} onClick={()=>setAdditionalWindowClassName('windowFullscreen')}></button>*/}
                    { options.canBeMaximized ?
                        <button className={'windowButton windowButtonMaximize'} onClick={()=>toggleMaximizeWindow()}></button>
                        :
                        ''
                    }
                </div>
                <div className={'windowCloseWhenFullscreen ' + (isWindowOpened && isWindowMaximized ? '' : 'hidden') }  onClick={()=>toggleMaximizeWindow()}>
                    <img src={closeIcon} alt='Close' />
                </div>
                <div className={'windowBody'}>
                    <Element options={options}/>
                </div>
            </div>
            <div className={'windowMinimized ' + (isWindowOpened && isWindowMinimized ? '' : 'hidden')}>
                <MinimizedElement name={name}/>
            </div>
        </>
    )
}

export default Window;
