import React from "react";
import ToggleDisplayMode from "../ToggleDisplayMode";
import './Settings.css'

const Settings = () => {

    return (
        <div className={'settingsGroup'}>
            <div className={'settingsItem'}>
                <ToggleDisplayMode />
            </div>
        </div>
    )
}

export default Settings;