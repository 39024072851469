import React, {useCallback, useEffect, useState, useRef } from "react";
import './Radio.css'
// import { RadioSourceModel, RadioSourceCollection } from './RadioSourceModel'

// import mp3 from '../../mp3/kampus_20200608_1345_Naukat.mp3';
// https://www.geeksforgeeks.org/create-a-music-player-using-javascript/

// https://www.liveatc.net/topfeeds.
// radio.garden

const Radio = ({options}) => {

    const audioplayer_id = 'audioplayer';
    const [ currentSource, setCurrentSource ] = useState({});
    const [ subpage, setSubpage ] = useState('search')

    // const myRadioSourceCollection = new RadioSourceCollection();

    const playlist = [
        {
            name: 'Kampus',
            url: 'https://stream.radiokampus.fm/kampus'
        }
        ,{
            name: 'RDC',
            url: 'http://stream2.nadaje.com:11140/rdc'
        }
        ,{
            name: 'BBC4extra',
            url: 'https://as-dash-ww-live.akamaized.net/pool_904/live/ww/bbc_radio_four_extra/bbc_radio_four_extra.isml/dash/bbc_radio_four_extra-audio=96000-260662862.m4s'
        }
        ,{
            name: 'WQCD-DB Smooth Jazz CD',
            url: 'http://144.217.158.59:5274/;?listening-from-radio-garden=1667730009'
        }
        ,{
            name: 'KLGA Tower #1 - New York, New York, United States',
            url: 'https://s1-fmt2.liveatc.net/klga_twr?nocache=2022110605245979543'
        }
        ,{
            name: 'RJAA Approach, Tokyo, Japan',
            url: 'https://s1-fmt2.liveatc.net/rjaa_app_s?nocache=2022110605270644193',
            isFavorite: true
        }
        ,{
            name: "Naukat 20220608",
            url: "./mp3/kampus_20200608_1345_Naukat.mp3",
        }
        ,{
            name: "Kampus Główny",
            url: "./mp3/kampus_20200609_1100_KampusGlowny.mp3",
        }

    ]

    const Playlist = ({onlyFavorites, playlist, onChangeSource, onToggleFavoriteSource, query}) => {

        return playlist
            .filter( p => RegExp(query).test(p.name) )
            .filter( p => (onlyFavorites ? p.isFavorite : true) )
            .map( p =>
            <li>
                <button className={'favoriteButton ' + (p.isFavorite ? 'active' : '')} onClick={()=>onToggleFavoriteSource(p)}></button>
                <div  onClick={()=>onChangeSource(p)}>
                    <span className={'name'}>{p.name}</span>
                    <span className={'url'}>{p.url}</span>
                </div>
            </li>
        )
    }

    const handleChangeSource = ( playlistItem ) => {
        console.log('Selected source:', playlistItem);
        setCurrentSource(playlistItem);
        let player = document.querySelector('#'+audioplayer_id);
        player.pause();
        // let player = document.querySelector('#'+audioplayer_id);
        // player.src = playlistItem.url;
        // player.autoplay = true;
    }

    const handleToggleFavoriteSource = (playlistItem) => {
        playlist.forEach( p => {
          if (p.name === playlistItem.name) {
              p.isFavorite = !p.isFavorite;
          }
        })
        console.log(playlist);
    }

    const [query, setQuery] = useState('');

    useEffect( () => {
        if (options.isOpened) {
            handleChangeSource(playlist[0]);
        } else {
            let player = document.querySelector('#'+audioplayer_id);
            player.pause();
        }
    }, [options.isOpened])

    useEffect( () => {
        console.log('Playlist changed');
    }, [playlist])

    // Disable rerendering on every change
    const RadioPlayer = useCallback(({audioplayerId, currentSourceName, src}) => {
        console.log('Rendering player radio...', currentSourceName, src);
        return (
            <div className={'currentSourceContainer'}>
                <div className={'currentSourceName'}>{currentSourceName}</div>
                <audio id={audioplayerId} controls={" "} src={src}
                       style={{height: '40px', width: '100%'}}></audio>
            </div>
        )
    }, [currentSource.name])

    const SearchSources = ({onlyFavorites, query, onChangeSource, onToggleFavoriteSource}) => {

        const inputField = useRef(null)

        useEffect( () => {
            inputField.current.focus();
        })

        return (
            <>
                <div className={'PlaylistQueryContainer'}>
                    <input ref={inputField} type="text" value={query} onChange={(e)=>setQuery(e.target.value)} placeholder='Search sources...'/>
                </div>
                <ul className={'PlaylistList'}>
                    <Playlist onlyFavorites={onlyFavorites} playlist={playlist} query={query} onChangeSource={onChangeSource} onToggleFavoriteSource={onToggleFavoriteSource}/>
                </ul>
            </>
        )
    }


    const RecordSettings = () => {
        return  (
            <>
                <div>Not available</div>
            </>
        )
    }

    const AddSource = () => {

        // const createNew = () => {
        //     const myRadioSourceModel = new RadioSourceModel();
        //     myRadioSourceModel.save( {
        //         name: 'Backbone Radio',
        //         url: 'not valid url for this source, sorry',
        //         isFavorite: true,
        //     })
        //     // https://stackoverflow.com/questions/6879138/how-to-save-an-entire-collection-in-backbone-js-backbone-sync-or-jquery-ajax
        //     myRadioSourceCollection.add(myRadioSourceModel);
        //     // myRadioSourceCollection.sync('create',myRadioSourceModel);
        //     console.log('myRadioSourceCollection', myRadioSourceCollection);
        // }

        console.log('Source, Rendering')

        // useEffect( () => {
        //     console.log('Source, useEffect')
        // }, [myRadioSourceCollection.length])

        return  (
            <>
                <div>Not available</div>
                {/*<button onClick={()=>createNew()}>Create one</button>*/}
                {/*<pre><h1>AAA</h1>{ JSON.stringify(myRadioSourceCollection) }</pre>*/}
            </>
        )
    }


    return (
        <div className={'Radio'}>
            <div className={'RadioMenuButtons'}>
                <button onClick={()=>setSubpage('search')}>All</button>
                <button onClick={()=>setSubpage('favorites')}>Favorites only</button>
                <button onClick={()=>setSubpage('record')}>Record</button>
                <button onClick={()=>setSubpage('addSource')}>Add source</button>
            </div>

            {subpage==='search' ?
                <SearchSources onlyFavorites={false} query={query} onChangeSource={handleChangeSource} onToggleFavoriteSource={handleToggleFavoriteSource}/>
                : ''}
            {subpage==='favorites' ?
                <SearchSources onlyFavorites={true} query={query} onChangeSource={handleChangeSource} onToggleFavoriteSource={handleToggleFavoriteSource} />
                : ''}

            {subpage==='record' ?
                <RecordSettings />
                : ''}

            {subpage==='addSource' ?
                <AddSource />
                : ''}
            {/*<div className={'PlaylistQueryContainer'}>*/}
            {/*    <input type="text" value={query} onChange={(e)=>setQuery(e.target.value)} placeholder='Search sources...'/>*/}
            {/*</div>*/}
            {/*<ul className={'PlaylistList'}>*/}
            {/*    <Playlist playlist={playlist} query={query} onChangeSource={handleChangeSource} onToggleFavoriteSource={handleToggleFavoriteSource}/>*/}
            {/*</ul>*/}

            <RadioPlayer audioplayerId={audioplayer_id} currentSourceName={currentSource.name} src={currentSource.url}/>

        </div>
    )

}

export default Radio;
