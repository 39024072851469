import React, {Component} from 'react'
import './MyClock.css'
import Clock from 'react-clock'
import 'react-clock/dist/Clock.css';

export default class MyClock extends Component {
    constructor(props) {
        super(props);
        this.state = {date: new Date()};
    }

    componentDidMount() {
        setInterval(
            () => this.setState({ date: new Date() }),
            1000
        );
    }

    render() {
        return (
            <div className={'clock'}>
                <Clock
                    value={this.state.date}
                />
                <h3>{this.state.date.toLocaleTimeString()}.</h3>
            </div>
        );
    }
}