import React from "react";

const Inception = () => {
    const url = 'https://vertebyte.pl';
    const arkanoidStyles = {
        width: '390px',
        height: '640px',
        border: 0
    }
    return (
        <div style={arkanoidStyles}>
            <iframe src={url} style={arkanoidStyles}></iframe>
        </div>
    )
}

export default Inception;